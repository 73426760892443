import { scrollElementIntoView, waitForElement } from '@ko-group/kodex';

const listenAndScroll = async () => {
	scrollElementIntoView(await waitForElement('.input-error'));
};

export const init = () => {
	document.querySelectorAll('form').forEach(form => {
		form.addEventListener('submit', listenAndScroll);
	});
};

init();
